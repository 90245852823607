import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';

import logo from '../img/logo.png'

import { TweenMax } from "gsap";
import { Link } from 'gatsby';

const useStyles = makeStyles({
  root: {
    paddingTop: '2px',
    paddingBottom: '2px'
  },
  menuBtn: {
    float: 'right',
    backgroundColor: 'transparent',
    border: 'none',
    margin: '15px'
  },
  menuIcon: {
    fontSize: '30px !important',
    color: '#fdbd11',
  },
  menuClose: {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '10px',
    cursor: 'pointer',
    color: '#131313',
    zIndex: '9999999'
  },
  fullList: {
    minWidth: '350px',
    width: '30vW',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#fdbd11'
  },
  listItem: {
    fontSize: '18px !important',
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
  },
  listItemSub: {
    fontSize: '14px !important',
  }
});

export default function Navbar() {
  const classes = useStyles();
  const [menu, setState] = React.useState({
    left: false,
  });

  const [colorChange, setColorchange] = React.useState(false);
  if (typeof window !== "undefined") {
    const scroller = window;
    const changeNavbarColor = () => {
        if (scroller.scrollY >= 80) {
          setColorchange(true);
        }
        else {
          setColorchange(false);
        }
    };
    scroller.addEventListener('scroll', changeNavbarColor);
  }

  let navMenu = [{ title: 'Home', path: '/' }, { title: 'About Us', path: '/about' }, { title: 'Curriculum', path: '/curriculum' }, { title: 'Adults Program', path: '/adults' }, { title: 'On stage and Beyond', path: '/onstagebeyond' }, { title: 'Gallery', path: '/gallery' }, { title: 'FAQ', path: '/faq' }, { title: 'Contact Us', path: '/contact' }]
  let navMenuSub = []
  let path = '';

  if (typeof window !== "undefined") {
    path = window.location.pathname;

    if (path === '/curriculum') {
      navMenuSub = [{ title: 'Launch To Ascend', path: '#launchToAscent' }, { title: 'Launch', path: '#launch' }, { title: 'Ignite', path: '#ignite' }, { title: 'Propel', path: '#propel' }, { title: 'Ascend', path: '#ascend' }]
    }

    if (path === '/about') {
      navMenuSub = [{ title: 'Theatre-led Methodology', path: '#theatreLedMethodology' }, { title: 'Social and Emotional Learning', path: '#socialLearning' }, { title: 'The Team', path: '#team' }]
    }
  }

  useEffect(() => {
    TweenMax.to(".navbar", 3, { css: { visibility: 'visible' } })

    // tl.from(heroImage, 3, {y: -1200, ease: Power3.easeOut},'Start')
    // .from(heroImage.firstElementChild, 2, {scale: 1.6, ease: Power3.easeOut}, .5)
  });

  const [checked, setChecked] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...menu, [anchor]: open });
    setChecked((prev) => !prev);
  };

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="navbar-collapse"
    >
      <List className={classes.fullList} >
        <i className={`fas fa-times ${classes.menuIcon} ${classes.menuClose}`}></i>

        {navMenu.map((item, index) => (
          <React.Fragment key={item.title}>
            <Slide direction="right" in={checked} mountOnEnter unmountOnExit
              style={{ transformOrigin: '0 0 0' }}
              {...(checked ? { timeout: 1000 * index / 4 } : {})}
            ><Link to={item.path+'?isProgramSelected=true'}>
                <ListItem button className={classes.listItem}>
                  <ListItemText primary={item.title} />
                </ListItem>
                <div>
                  {item.path === path ? (
                    navMenuSub.map((subItem, subIndex) => {
                      return (
                        <Link to={item.path + subItem.path}>
                          <ListItem button className="nav-item-sub">
                            <ListItemText className="nav-item-sub-text" primary={subItem.title} />
                          </ListItem>
                        </Link>
                      )
                    })
                  ) : (null)}
                </div>
              </Link>

            </Slide>
          </React.Fragment>
        ))}

        <Slide direction="right" in={checked} mountOnEnter unmountOnExit
          style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 2000 } : {})}
        >
          <ListItem>
          <Link to="/contact?isProgramSelected=true">
            <button variant="contained" color="primary" className="btn btn-accent">
              Join Now
            </button>
          </Link>
          </ListItem>
        </Slide>


        <Slide direction="right" in={checked} mountOnEnter unmountOnExit
          style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 2300 } : {})}
        >
          <ListItem className="navbar-social-wrap">
            <Link to="https://www.facebook.com/Sesquipedalian7" target="_blank">
              <span className="social-icon">
                <i className="fab fa-facebook-f"></i>
              </span>
            </Link>
            <Link to="https://www.instagram.com/sesquipedalian_learning" target="_blank">
              <span className="social-icon">
                <i className="fab fa-instagram"></i>
              </span>
            </Link>
            {/* <Link to="https://twitter.com">
              <span className="social-icon">
                <i class="fab fa-twitter"></i>
              </span>
            </Link> */}
          </ListItem>
        </Slide>

        <Slide direction="right" in={checked} mountOnEnter unmountOnExit
          style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 2300 } : {})}
        > 
          <ListItem>
            <div className="navbar-contact">
                <h2><a href="tel:0777768769">0777 768 769</a></h2>
                <h3>(Call/WhatsApp)</h3>
            </div>
          </ListItem>

        </Slide>
      </List>
    </div>
  );

  return (
    <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer anchor={anchor} open={menu[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      <div className={colorChange ? 'navbar nav-color-change' : 'navbar'} >
        <Link to="/?isProgramSelected=true"><span className="logo"><img src={logo} /></span></Link>
        <button onClick={toggleDrawer("left", true)} className={classes.menuBtn}>
          <i className={`fas fa-bars ${classes.menuIcon}`}></i>
        </button>
      </div>
    </div>
  );
}
