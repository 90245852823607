import React from 'react'
import { Link } from 'gatsby'

import Grid from '@material-ui/core/Grid';

const Footer = class extends React.Component {
  

  render() {
    let date = new Date();
    var year = date.getFullYear();
    return (
      <footer className="section-footer">
        <Grid container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} >
            <h3 className="has-text-weight-semibold is-size-2 is-size-4-mobile is-size-2-tablet is-size-1-widescreen intro-heading">
              <span className="footer-title">Sesquipedalian</span>
            </h3>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={4} >
            <ul className="menu-list">
              <li>
                <Link to="/" className="navbar-item">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="navbar-item">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/curriculum" className="navbar-item">
                  Curriculum
                </Link>
              </li>
              <li>
                <Link to="/onstagebeyond" className="navbar-item">
                On stage and Beyond
                </Link>
              </li>
              <li>
                <Link to="/gallery" className="navbar-item">
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/faq" className="navbar-item">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/contact" className="navbar-item">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/privacypolicy" className="navbar-item">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </Grid>
    
          <Grid item xs={12} md={4} >
          <div class="footer-address mb-mt-2">
                <p>Follow Us On</p>
          </div>
          <div className="footer-social mb-mt--0-25">
              <a title="facebook" href="https://www.facebook.com/Sesquipedalian7" className="social-icon" target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a title="facebook" href="https://www.instagram.com/sesquipedalian_learning" className="social-icon" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
              
              {/* <a title="facebook" href="https://twitter.com" className="social-icon">
                <i className="fab fa-twitter"></i>
              </a> */}
            </div>
          </Grid>

          <Grid item xs={12} md={4} >
              <div class="footer-address">
                <p>Contact Us On</p>
                <p className="text-bold">0777 768 769</p>
              </div>
                <br />
                <div class="footer-address">
                <p>We currently conduct our classes at</p>
                  <p className="text-bold">Oneness Centre,</p>
                  <p className="text-bold">3A Col TG Jayawardena Mawatha,</p>
                  <p className="text-bold">Colombo 00300</p>
                </div>
                <br />
                <div class="footer-address">
                <p>Email</p>
                <p className="text-bold">visitus@sesquipedalian.lk</p>
              </div>
          </Grid>

          <Grid item xs={12} md={12} >
              <div class="footer-copyrigts">
                <small>&copy; Copyright {year}, Sesquipedalian</small>
              </div>
          </Grid>

        </Grid>
      </footer>
    )
  }
}

export default Footer
