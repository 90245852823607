import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './styles.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'


const TemplateWrapper = ({ children, isProgramTypeSelected}) => {
  const { title, description, url, keywords } = useSiteMetadata();


  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css" integrity="sha512-HK5fgLBL+xu6dm/Ii3z4xhlSUyZgTT9tuc/hSrtw6uzJOvgRr2a9jyxxT1ely+B+xFAmJKVSTbpM/CuL7qxO8w==" crossorigin="anonymous" />
        <meta name="theme-color" content="#fdbd11" />

        <meta property="og:type" content="education" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={url} />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-new-image.jpg`}
        />
        <meta name="google-site-verification" content="GUlM8KFoZxRsqFkjocYPtvCS1VV32teZ3k5RAGA6Rz8" />
        <script async={true} defer={true} crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0&appId=192457652446226&autoLogAppEvents=1" />
        <meta name="facebook-domain-verification" content="bt27z7xt37zfaielhev83n3z9glmue" />
      </Helmet>
        {isProgramTypeSelected ? (<Navbar />) : (null) }
        <div>{children}</div>
        {isProgramTypeSelected ? (<Footer />) : (null) }
    </div>
  )
}

export default TemplateWrapper
